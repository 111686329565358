import axios from "axios";
import AuthService from '@/services/authService'
import router from '@/router/index'
import jwt_decode from "jwt-decode";
 

// const BASE_URL = 'https://pharma-api.justcompileit.com/v1';
// const BASE_URL = 'http://localhost:3030/v1';
// const BASE_URL = 'https://rewards-api.pharma-call.es/v1';

const BASE_URL = 'https://rewards-api.pharma-call.es/v1';
const HEADERS = {
    headers: {
        'Authorization': 'Bearer ' + AuthService.getToken()
    }
};

axios.interceptors.request.use(
    async config => {
      let token = localStorage.getItem('token')
      if (token) {
        const decoded = jwt_decode(token);
        console.log(decoded);
      } else {
          token = ''
      }
      config.headers = { 
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',        
      }
      return config;
    },
    error => {
      Promise.reject(error)
  });

axios.interceptors.response.use(function (response) {
    if (response && response.config) {
        console.log(response.config.url)
        if (response.config.url != `${BASE_URL}/auth/refresh-tokens` && response.config.url != `${BASE_URL}/auth/login` ) {
            /*
            const refresh = localStorage.getItem('token-refresh')   
            console.log(refresh)     
            if (refresh) {
                axios.post(`${BASE_URL}/auth/refresh-tokens`, { refreshToken : refresh }, HEADERS).then( data => {
                    console.log(data)
                    if ( data && data.access ) {
                        localStorage.setItem('token', data.access.token)
                    }
                })
            }
            */
        }    
    }
    return response;
}, function (error) {
    if (401 === error.response.status && error.response.config.url != `${BASE_URL}/auth/login`) {     
        // console.log(error)
        localStorage.clear();
        router.go('/login')                
    } else {
        return Promise.reject(error);
    }
});

export default {
    get(url) {
        return axios.get(`${BASE_URL}${url}`, HEADERS)
    },
    getBlob(url) {
        return axios.get(`${BASE_URL}${url}`, { responseType: 'blob'})
    },
    postUnauth(url, body) {
        return axios.post(`${BASE_URL}${url}`, body)
    },
    post(url, body) {
        return axios.post(`${BASE_URL}${url}`, body, HEADERS)
    },
    put(url, body) {
        return axios.put(`${BASE_URL}${url}`, body, HEADERS)
    }    
  }